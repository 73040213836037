import { FormattedMessage, useIntl } from "react-intl";
import { Area } from "@ant-design/plots";

import {
  useCurrentUser,
  useHarvestDailyReport,
  useHarvestOverviewReport,
} from "../../lib/hooks";
import dayjs from "dayjs";
import { Card, Col, Progress, Row } from "antd";
import {
  formatUnitValue,
  roundUnit,
  useEstimationUnitSwitch,
} from "../../lib/formats";
import { SortDirection } from "../../lib/graphql";
import { useMemo } from "react";
import { Space, Statistic } from "../shared";

export function HarvestOverview() {
  const intl = useIntl();
  const { currentLocalityIds, currentCropCycle, currentCropCycleIds } =
    useCurrentUser();

  const { showInEstimationUnits, showInEstimationUnitsSwitch } =
    useEstimationUnitSwitch();
  const { loading: overviewLoading, report } = useHarvestOverviewReport({
    variables: {
      filter: {
        localityId: currentLocalityIds,
        cropCycleId: currentCropCycleIds,
        showInEstimationUnits,
      },
    },
  });

  const { loading: harvestLoading, items: harvestItems } =
    useHarvestDailyReport({
      variables: {
        filter: {
          localityId: currentLocalityIds,
          cropId: currentCropCycle?.crops.map((c) => c.id),
          showInEstimationUnits,
        },
        sort: { documentDate: SortDirection.Desc },
        pageSize: 1000,
      },
    });

  const currentYear = dayjs(currentCropCycle?.startedAt).year();
  const harvestFormatter = (v: number | null | undefined) =>
    `${intl.formatNumber(v || 0, { maximumFractionDigits: 2 })} ${
      report?.harvestUnitAbbr || (harvestItems || [])[0]?.harvestUnitAbbr
    }`;

  const data = useMemo(
    () =>
      (harvestItems || []).map(
        ({ documentDate, cropCycle, accumulatedHarvest }) => {
          const dateInCurrentYear = dayjs(documentDate)
            .add(currentYear - dayjs(cropCycle?.startedAt).year(), "year")
            .toDate();

          return {
            date: documentDate,
            dateInCurrentYear,
            cropCycle: cropCycle.name,
            amount: accumulatedHarvest,
          };
        }
      ),
    [currentYear, harvestItems]
  );

  if (!harvestItems?.length) return null;

  return (
    <Col xs={24} md={24}>
      <Card loading={overviewLoading}>
        <Row gutter={32}>
          {report && report.harvestedAmount && (
            <Col flex="200px">
              <Card
                bordered={false}
                loading={overviewLoading}
                bodyStyle={{ padding: 0 }}
                style={{ textAlign: "center" }}
              >
                <Space direction="vertical" size="large">
                  <div>
                    <Statistic
                      title={<FormattedMessage id="workOrders.harvested" />}
                    >
                      {harvestFormatter(report.harvestedAmount)}
                    </Statistic>
                    {report.estimateAmount && (
                      <Progress
                        percent={roundUnit(
                          (100 * report.harvestedAmount) / report.estimateAmount
                        )}
                      />
                    )}
                  </div>

                  {report.estimateAmount && (
                    <div>
                      <Statistic
                        title={
                          <FormattedMessage id="harvestEstimates.estimatedHarvest" />
                        }
                      >
                        {harvestFormatter(report.estimateAmount)}
                      </Statistic>
                    </div>
                  )}

                  {report.harvestYield && (
                    <Statistic
                      title={
                        <FormattedMessage id="reports.harvest.harvestYield" />
                      }
                    >
                      {formatUnitValue(
                        report?.harvestYield,
                        {
                          abbr: report.harvestUnitAbbr || "",
                        },
                        report.areaUnitAbbr
                      )}
                    </Statistic>
                  )}

                  {showInEstimationUnitsSwitch}
                </Space>
              </Card>
            </Col>
          )}

          <Col flex="auto">
            <Area
              loading={harvestLoading}
              data={data}
              xField="dateInCurrentYear"
              yField="amount"
              seriesField="cropCycle"
              height={300}
              autoFit={false}
              smooth
              isStack={false}
              meta={{
                amount: {
                  alias: intl.formatMessage({
                    id: "reports.harvest.accumulatedHarvested",
                  }),
                  formatter: harvestFormatter,
                },
              }}
              xAxis={{
                type: "time",
                label: {
                  formatter(text) {
                    return intl.formatDate(text, {
                      month: "short",
                    });
                  },
                },
              }}
              legend={{
                position: "top-right",
                marker: { symbol: "circle", style: { fillOpacity: 1 } },
              }}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
}
