import { Card, Col, Row } from "antd";
import { useState } from "react";
import routes from "../../lib/routes";
import { Space } from "../shared";
import { Localities } from "./Localities";
import Welcome from "./Welcome";
import { hardRedirect } from "../../lib/redirect";

export default function Onboarding() {
  const [currentStep, setCurrentStep] = useState("welcome");

  const onNextStep = (step: string) => () => {
    setCurrentStep(step);
    window.scrollTo(0, 0);
  };

  const finish = () => {
    hardRedirect(routes.onboarding + "?pillars=true");
  };

  if (currentStep == "welcome") {
    return <Welcome onNextClick={onNextStep("companySettings")} />;
  }

  return (
    <Row justify="center" align="middle">
      <Col xs={24} md={16}>
        <Card>
          <Space vertical size="large">
            <Localities onNextClick={finish} />
          </Space>
        </Card>
      </Col>
    </Row>
  );
}
