import { Avatar, Col, Row, Space, Spin, Typography } from "antd";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import BasicLayout from "../layouts/BasicLayout";
import {
  formatAssetPath,
  formatDate,
  formatTenantName,
  formatTestDataResetAt,
  useCurrencySwitch,
} from "../lib/formats";
import { useCurrentUser, useMe } from "../lib/hooks";
import routes from "../lib/routes";
import Dashboard from "../components/dashboard/Dashboard";
import Onboarding from "../components/onboarding";
import SiteProductTour from "../components/onboarding/ProductTour";
import { useInterval } from "react-use";
import { useSearchParams } from "next/navigation";
import { SubscriptionPlans } from "../components/subscriptionPlans";

const messages = defineMessages({
  pageTitle: { id: "dashboard.pageTitle", defaultMessage: "Dashboard" },
});

function CreatingSubscription() {
  const { refetch } = useMe();

  useInterval(() => {
    refetch();
  }, 5000);

  return (
    <Space align="center" direction="vertical" style={{ marginTop: "40vh" }}>
      <Spin tip={<FormattedMessage id="user.signUp.creating" />} />
    </Space>
  );
}

function Home() {
  const intl = useIntl();
  const { currentTenant, forceOnboarding, forceSubscription } =
    useCurrentUser();

  const { currentCurrency, currencySwitch } = useCurrencySwitch();

  const searchParams = useSearchParams();

  const accountStateParam = searchParams.get("accountState");
  // this is flow for new accounts
  // old accounts should create farm manually, so they'll need to go thru onboarding flow
  const creatingSubcription =
    forceOnboarding && accountStateParam === "creating";

  const tenantName = formatTenantName(currentTenant);

  return (
    <BasicLayout
      title={intl.formatMessage(messages.pageTitle)}
      breadcrumbs={
        forceOnboarding || forceSubscription
          ? undefined
          : [
              {
                path: routes.dashboard,
                breadcrumbName: intl.formatMessage(messages.pageTitle),
              },
            ]
      }
      headerContent={
        !(forceOnboarding || forceSubscription) && (
          <Row justify="space-between" align="bottom">
            <Col>
              <Avatar
                src={currentTenant.logo && formatAssetPath(currentTenant.logo)}
                size={60}
                alt={`${tenantName} logo`}
                style={{ marginRight: 16 }}
              >
                {tenantName?.[0]}
              </Avatar>
              <Typography.Text type="secondary">
                {currentTenant.liveTenantId ? (
                  formatTestDataResetAt(currentTenant)
                ) : (
                  <FormattedMessage
                    id="dashboard.subtitle"
                    defaultMessage="dashboard.subtitle"
                    values={{
                      createdAt: formatDate(currentTenant.createdAt, {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      }),
                    }}
                  />
                )}
              </Typography.Text>
            </Col>
            <Col>{currencySwitch}</Col>
          </Row>
        )
      }
      contentStyle={{ background: "transparent", padding: "0px" }}
    >
      {creatingSubcription ? (
        <CreatingSubscription />
      ) : forceSubscription ? (
        <SubscriptionPlans />
      ) : forceOnboarding ? (
        <Onboarding />
      ) : (
        <>
          <SiteProductTour />
          <Dashboard currentCurrency={currentCurrency} />
        </>
      )}
    </BasicLayout>
  );
}

export default Home;
